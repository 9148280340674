import React from "react";
import * as styles from "./index.module.less";
import BlokContainer from "@/components/blok-container";
import { Grid, GridItem, Center, Box, Text } from "@chakra-ui/react";
import { NOTABLE_FEATURES } from "../constants";

const NotableFeatures = () => {
  return (
    <BlokContainer
      maxW="web_max_w"
      blockTitle="Other Notable Features that Makes Airgram Standout"
      pt="0"
    >
      <Grid
        w="full"
        gridTemplateColumns={{
          base: "1fr",
          sm: "1fr",
          md: "1fr 1fr",
          lg: "1fr 1fr",
          xl: "1fr 1fr 1fr",
          "2xl": "1fr 1fr 1fr",
        }}
        gridColumnGap="32px"
        gridRowGap={{
          base: "20px",
          sm: "32px",
        }}
        px={{
          base: "0px",
          sm: "80px",
          md: "0px"
        }}
      >
        {NOTABLE_FEATURES.map((featureItem, index) => {
          return (
            <GridItem key={index}>
              <Center
                justifyContent="flex-start"
                w="full"
                h="full"
                p={{
                  base: "16px",
                  sm: "16px 24px",
                  lg: "24px",
                }}
                border="1px solid #EAEDF0"
                boxShadow="0px 4px 16px 0px rgba(68, 68, 68, 0.10)"
                borderRadius="16px"
                columnGap={{
                  base: "16px",
                  sm: "20px",
                  md: "20px",
                  lg: "24px",
                  xl: "24px",
                  "2xl": "24px",
                }}
              >
                <Center
                  bg={featureItem.background}
                  borderRadius="8px"
                  p={{
                    base: "6px",
                    sm: "10px",
                  }}
                >
                  <Box
                    as={featureItem.icon}
                    color={featureItem.color}
                    w={{
                      base: "20px",
                      sm: "28px",
                    }}
                    h={{
                      base: "20px",
                      sm: "28px",
                    }}
                  ></Box>
                </Center>
                <Center flex={1}>
                  <Text as="h4">{featureItem.title}</Text>
                </Center>
              </Center>
            </GridItem>
          );
        })}
      </Grid>
    </BlokContainer>
  );
};
export default NotableFeatures;

import { Flex, Text, List, ListItem } from "@chakra-ui/react";
import React from "react";
import {
  NotesTag,
  PublicSearch,
  PublicLink,
  TranscriptExportVideo,
  TranscriptClip,
  PublicExportAsDocument,
} from "react-notticon";

export const FEATURES_LIST = [
  {
    content: (
      <Flex flexDir="column" gap="12px">
        <Text as="p">
          Schedule events directly from Google Calendar and create an agenda to
          keep your meetings on track.
        </Text>

        <List listStyleType="disc" pl="2em" fontWeight="400">
          <ListItem>
            <Text>Allot time for each talking point</Text>
          </ListItem>
          <ListItem>
            <Text>Pre-built agenda templates for free</Text>
          </ListItem>
          <ListItem>
            <Text>Email notification for upcoming meetings</Text>
          </ListItem>
        </List>
      </Flex>
    ),
    img: {
      src: "https://a.storyblok.com/f/167495/400x300/4cbcb220c9/01.svg",
      alt: "create meeting agenda using Airgram",
    },
    title: "Create meeting agenda",
  },
  {
    content: (
      <Flex flexDir="column" gap="12px">
        <Text>
          Record meetings in high quality video with real-time transcripts;
          easily accessible for reference at any time.
        </Text>

        <List listStyleType="disc" pl="2em" fontWeight="400">
          <ListItem>
            <Text>Accurate speaker identification</Text>
          </ListItem>
          <ListItem>
            <Text>
              8 transcription languages available, not limited to English
            </Text>
          </ListItem>
          <ListItem>
            <Text>Automatically join scheduled meetings</Text>
          </ListItem>
        </List>
      </Flex>
    ),
    img: {
      src: "https://a.storyblok.com/f/167495/1200x900/48379af197/02.webp",
      alt: "Airgram transcribes Google Meet calls",
    },
    title: "Record and live transcribe Google Meet calls",
  },
  {
    content: (
      <Flex flexDir="column" gap="12px">
        <Text>
          Jot down important information during meetings on the integrated
          notepad without switching tools; all work done in one place.
        </Text>

        <List listStyleType="disc" pl="2em" fontWeight="400">
          <ListItem>
            <Text>
              Rich-text editor to add text, images, bullet list, and more
            </Text>
          </ListItem>
          <ListItem>
            <Text>Sync and async collaboration on notes</Text>
          </ListItem>
          <ListItem>
            <Text>Create action items with due dates</Text>
          </ListItem>
        </List>
      </Flex>
    ),
    img: {
      src: "https://a.storyblok.com/f/167495/1200x900/546b798530/03.webp",
      alt: "take notes during a Google Meet meeting",
    },
    title: "Take down notes in rich-text formatting",
  },
  {
    content: (
      <Flex flexDir="column" gap="12px">
        <Text>
          Revisit the recording, note, and transcript immediately after the
          meeting is over or at any time you want.
        </Text>

        <List listStyleType="disc" pl="2em" fontWeight="400">
          <ListItem>
            <Text>Meeting recording playback an adjustable speed</Text>
          </ListItem>
          <ListItem>
            <Text>Edit, copy, or comment on the transcript freely</Text>
          </ListItem>
          <ListItem>
            <Text>AI Topic extracts key information</Text>
          </ListItem>
        </List>
      </Flex>
    ),
    img: {
      src: "https://a.storyblok.com/f/167495/1200x900/4c12edccd7/04.webp",
      alt: "review Google meet meeting recording in Airgram",
    },
    title: "Review meetings with clear takeaway",
  },
];

export const NOTABLE_FEATURES = [
  {
    title: "Meeting tag",
    icon: NotesTag,
    color: "#589EFF",
    background: "#DEECFF",
  },
  {
    title: "Smart search",
    icon: PublicSearch,
    color: "#56EF93",
    background: "#56EF9333",
  },
  {
    title: "Timestamps",
    icon: PublicLink,
    color: "#FFC963",
    background: "#FFC96333",
  },
  {
    title: "Download video recording",
    icon: TranscriptExportVideo,
    color: "#FFA366",
    background: "#FFA36633",
  },
  {
    title: "Make clips",
    icon: TranscriptClip,
    color: "#FF8989",
    background: "#FF898933",
  },
  {
    title: "Export notes",
    icon: PublicExportAsDocument,
    color: "#A276FF",
    background: "#A276FF33",
  },
];

import React from "react";
import * as styles from "./index.module.less";
import BlokContainer from "@/components/blok-container";
import { Grid, GridItem, Center, Flex, Text, Image } from "@chakra-ui/react";
import { MeetingsStarredFilled } from "react-notticon";
import TechCrunchIcon from "@/assets/images/TC.svg";

const UserComments = () => {
  return (
    <BlokContainer
      maxW="web_max_w"
      blockTitle="Don’t Just Take Our Word for It"
      pt="0px"
    >
      <Grid
        h="full"
        gridTemplateColumns={{
          base: "1fr",
          sm: "1fr",
          md: "1fr 1fr",
          lg: "1fr 1fr",
          xl: "1fr 1fr",
          "2xl": "1fr 1fr",
        }}
        gridColumnGap={{
          base: "40px",
          xl: "40px",
          "2xl": "80px",
        }}
        gridRowGap={{
          base: "24px",
          sm: "24px",
          md: "24px",
        }}
      >
        <GridItem>
          <Center
            p={{
              base: "24px",
              sm: "24px 32px",
              lg: "24px 48px",
              "2xl": "40px 64px",
            }}
            borderRadius={{
              base: "24px",
              sm: "28px",
              md: "28px",
              lg: "32px",
              xl: "32px",
              "2xl": "32px",
            }}
            flexDir="column"
            h="full"
            justifyContent="space-between"
            className="google-meet-bottom-card1-deco"
          >
            <Flex flexDir="column" gap="24px">
              <Text as="h4" color="#ffffff" className="google-meet-card1-title">
                Otter.ai challenger Airgram transcribes and times your calls
              </Text>
              <Text color="#ffffff">
                Airgram helps people keep their meetings on track by letting
                users project their meeting agenda onto the screen with a timer
                that reminds them not to run over time.
              </Text>
            </Flex>
            <Flex w="full" gap="24px" mt="22px">
              <Center
                bg="#ffffff"
                w={{
                  base: "40px",
                  sm: "48px",
                  lg: "56px",
                }}
                h={{
                  base: "40px",
                  sm: "48px",
                  lg: "56px",
                }}
                borderRadius="100%"
                p="4px"
              >
                <Image
                  src={TechCrunchIcon}
                  objectFit="contain"
                  alt="TechCrunch"
                />
              </Center>
              <Center>
                <Text as="h4" flex={1} color="#ffffff">
                  TechCrunch
                </Text>
              </Center>
            </Flex>
          </Center>
        </GridItem>
        <GridItem>
          <Center
            p={{
              base: "24px",
              sm: "24px 32px",
              lg: "24px 48px",
              "2xl": "40px 64px",
            }}
            borderRadius={{
              base: "24px",
              sm: "28px",
              md: "28px",
              lg: "32px",
              xl: "32px",
              "2xl": "32px",
            }}
            flexDir="column"
            h="full"
            justifyContent="space-between"
            className="google-meet-bottom-card2-deco"
          >
            <Flex flexDir="column" gap="24px">
              <Text as="h4" color="#ffffff" className="google-meet-card2-title">
                Getting back to any information from any meeting is much easier
              </Text>
              <Text color="#ffffff">
                Airgram helped me attend meetings without having to worry about
                memorizing things or taking notes, I can now be fully present
                and get back to any information later on. Highly recommended!
              </Text>
            </Flex>
            <Flex w="full" gap="24px" mt="22px">
              <Center columnGap="8px" color="#F0492D">
                <MeetingsStarredFilled />
                <MeetingsStarredFilled />
                <MeetingsStarredFilled />
                <MeetingsStarredFilled />
                <MeetingsStarredFilled />
              </Center>
              <Center>
                <Text
                  as="span"
                  fontSize={{
                    base: "18px",
                    sm: "18px",
                    md: "20px",
                    lg: "20px",
                    xl: "24px",
                    "2xl": "24px",
                  }}
                  color="#ffffff"
                  style={{
                    fontWeight: "400",
                  }}
                >
                  By&nbsp;
                </Text>
                <Text as="h4" flex={1} color="#ffffff">
                  Ali R.
                </Text>
              </Center>
            </Flex>
          </Center>
        </GridItem>
      </Grid>
    </BlokContainer>
  );
};
export default UserComments;

import React from "react";
import * as styles from "./index.module.less";
import CTA from "@/components/cta";
import BlokContainer from "@/components/blok-container";
import customStyles from "@/assets/theme/customStyles";

const BottomCta = () => {
  return (
    <BlokContainer maxW={customStyles.web_max_w} pt="0">
      <CTA
        title={"Great Google Meet Meetings Starts Here"}
        buttonLink={
          "https://chrome.google.com/webstore/detail/google-meet-transcription/gfcfcfbheicekekhagikabglhfgnjdek"
        }
        buttonText={"Get Chrome Extension"}
        btnBg="#9F2AEC"
      ></CTA>
    </BlokContainer>
  );
};
export default BottomCta;

import React from "react";
import * as styles from "./index.module.less";
import BlokContainer from "@/components/blok-container";
import { FEATURES_LIST } from "../constants";
import GraphicText from "@/layout/GraphicText";
import { Center, Flex, Image } from "@chakra-ui/react";
import SymmetricalLayout from "@/components/symmetrical-layout";
import RatioMedia from "@/components/ratio-media";

const FeaturesDescList = () => {
  return (
    <BlokContainer
      maxW="web_max_w"
      blockTitle="Make the Most of Every Google Meet Call"
      blockSubTitle="You stay engaged in the meeting and discussion; let Airgram take
  care of the rest."
    >
      <Center
        w="full"
        flexDir="column"
        gap={{
          base: "48px",
          sm: "64px",
          xl: "80px",
          "2xl": "100px",
        }}
      >
        {FEATURES_LIST.map((item, index) => {
          return (
            <SymmetricalLayout
              key={item.title}
              title={item.title}
              media={
                <RatioMedia
                  media={{
                    type: "image",
                    src: item.img.src,
                    alt: item.img.alt,
                  }}
                  mediaStyle={{
                    borderRadius: "12px",
                  }}
                />
              }
              content={item.content}
              layout={index % 2 ? "media-content" : "content-media"}
            />
          );
        })}
      </Center>
    </BlokContainer>
  );
};
export default FeaturesDescList;

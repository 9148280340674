import * as React from "react";
import Layout from "@layout/index";

import Header from "@components/head";

import { Center, VStack } from "@chakra-ui/react";
import Honor from "@/components/honor";
import HeroBanner from "@/modules/product/google-meet/hero-banner";
import FeaturesDescList from "@/modules/product/google-meet/feature-list";
import NotableFeatures from "@/modules/product/google-meet/notable-features";
import UserComments from "@/modules/product/google-meet/user-commments";
import BottomCta from "@/modules/product/google-meet/bottom-cta";
import { HOST_URL } from "@/utils/constant";

export const Head = () => {
  return (
    <Header
      ogUrl={`${HOST_URL}/product/google-meet`}
      title="Airgram | One-stop Google Meet Record and Transcription Tool"
      description="Real-time transcription in multiple languages, meeting video recording, and integrated notepad - Airgram brings your Google Meet meeting productivity to the next level. Get its Chrome extension for free."
    />
  );
};

const IndexPage = () => {
  return (
    <Layout
      isNewStyle
      px="0px"
      margin="0px"
      minW="100%"
      pt="0px"
      navMaxW="web_max_w"
    >
      <VStack>
        {/* hero banner */}
        <HeroBanner />
        <Center
          w="full"
          mt={{
            base: "40px",
            sm: "64px",
            md: "64px",
            lg: "64px",
            xl: "140px",
            "2xl": "160px",
          }}
          bg="#F6F7F9"
        >
          <Center maxW="web_max_w">
            <Honor />
          </Center>
        </Center>
        {/* Make the Most of Every Google Meet Call */}
        <FeaturesDescList />
        {/* Other Notable Features that Makes Airgram Standout */}
        <NotableFeatures />
        {/* Don’t Just Take Our Word for It */}
        <UserComments />
        <BottomCta />
      </VStack>
    </Layout>
  );
};

export default IndexPage;

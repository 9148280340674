import React from "react";
import * as styles from "./index.module.less";
import { Center, Link, Box, Text, Flex } from "@chakra-ui/react";
import BlokContainer from "@/components/blok-container";
import CommonButton from "@/components/common-button";
import RatioMedia from "@/components/ratio-media";

const HeroBanner = () => {
  return (
    <BlokContainer maxW="web_max_w">
      <Center
        w="full"
        gap={{
          base: "40px",
          lg: "64px",
          xl: "80px",
        }}
        flexDir={{
          base: "column",
          xl: "row",
        }}
      >
        <Flex
          flex={1}
          color="#220247"
          flexDir="column"
          gap={{
            base: "24px",
            md: "48px",
            "2xl": "64px",
          }}
          alignItems={{
            base: "center",
            xl: "flex-start",
          }}
        >
          <Flex
            flexDir="column"
            gap="24px"
            textAlign={{
              base: "center",
              xl: "left",
            }}
          >
            <Text as="h1">
              Best Google Meet Extension for Productive Meetings
            </Text>
            <Text>
              Record, transcribe, document, and review Google Meet calls has
              never been easier. All of your meeting notes are in one workplace.
            </Text>
          </Flex>
          <CommonButton
            bg="linear-gradient(90deg, #E146F1 0%, #9047F1 100%)"
            content="Add to Chrome Free"
            to="https://chrome.google.com/webstore/detail/airgram-transcribe-record/gfcfcfbheicekekhagikabglhfgnjdek"
            w={{
              base: "200px",
              sm: "220px",
              lg: "250px",
            }}
          />
        </Flex>
        <Center
          maxW={{
            base: "480px",
            "2xl": "560px",
          }}
          w="full"
          overflow="hidden"
          borderRadius="12px"
        >
          <RatioMedia
            media={{
              type: "video",
              src: "https://d2rresadhaeqs7.cloudfront.net/Animation-Product-Google-Meet-Hero-Banner.mp4",
            }}
          />
        </Center>
      </Center>
    </BlokContainer>
  );
};
export default HeroBanner;
